import React, { FunctionComponent } from 'react';
import {
  Box, Flex, Text, Button, Modal, useDisclosure, ModalOverlay,
  ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
  HStack, Image,
  Icon,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Stack,
  Spacer,
  LinkOverlay,
  Link,
  GridItem,
  Grid,
} from '@chakra-ui/react';
import { FiMenu } from 'react-icons/fi';
import router from 'next/router';
import AuthForm from '../../login/authForm';
import { useAuth } from '../../../pages/api/userAuth';
import BasketIcon from '../../basket/basketIcon';
import isMobile from '../../../utils/isMobile';
import { IRole } from '../../../lib/graphql';
import NavLink from '../../navLink';

const Header: FunctionComponent = (props) => {
  const handleLoginClick = () => {
    if (user) {
      logout();
    } else {
      onOpen();
    }
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, loading, logout } = useAuth();
  const ref = React.useRef(null);
  const loginText = '';

  const openLogin = () => {
    onCloseMenu();
    onOpen();
  };

  const {
    isOpen: isOpenMenu,
    onOpen: onOpenMenu,
    onClose: onCloseMenu,
  } = useDisclosure();

  return (
    <Flex
      ref={ref}
      as="nav"
      align="center"
      flexGrow={1}
      padding={3}
      backgroundColor="#ffffff"
      width="100%"
      {...props}
    >
      <Grid gridTemplateColumns="1fr 300px 1fr" width="100%">
        <GridItem>&nbsp;</GridItem>
        <GridItem>
          <Box align="center" mr={5}>
            <Button onClick={() => router.push('/class-booking')} variant="unstyled" h="auto">
              {isMobile()
                ? <Image w="50px" src="/images/UKCPA-Logo-Mark-01.png" />
                : <Image w="200px" src="/images/UKCPA-logo-01.png" />}
            </Button>
          </Box>
        </GridItem>
        <GridItem display="flex" alignItems="center" justifyContent="right">

          <Box
            mt={{ base: 0, md: 0 }}
            alignItems="center"
          >
            <HStack>
              {user?.roles.includes(IRole.Admin) && <Link href="?preview=PREVIEW">Preview</Link>}

              {!isMobile() && (
              <Box pr={4}>
                <HStack>
                  <NavLink action={() => router.push('/class-booking')} label="Courses" />
                  {user && (
                  <NavLink action={() => router.push('/my-account')} label="My account" />
                  )}
                  <Box pr={4}>
                    <BasketIcon />
                  </Box>
                  {user ? (
                    <Button size="sm" onClick={() => logout()}>Log Out</Button>
                  ) : (
                    <Button size="sm" onClick={() => openLogin()}>Log In</Button>
                  )}
                </HStack>
              </Box>
              )}
              <Button onClick={onOpenMenu} colorScheme="yellow"><Icon as={FiMenu} /></Button>
              <Drawer
                id="leftMenu"
                isOpen={isOpenMenu}
                placement="right"
                onClose={onCloseMenu}
              >
                <Box bg="tomato" w="100%" p={4} color="white">
                  This is the Box
                </Box>
                <DrawerOverlay>
                  <DrawerContent>
                    <DrawerHeader borderBottomWidth="1px">
                      <Box height={4}>
                        <HStack>
                          <DrawerCloseButton />
                          {user ? (
                            <Text>
                          Hi
                          {' '}
                          {user.firstName}
                        </Text>
                          ) : ('')}
                        </HStack>
                      </Box>
                    </DrawerHeader>

                    <DrawerBody>
                      <Stack p={2} alignItems="flex-end">
                        <Text fontSize="xl">Account</Text>
                        <Button onClick={(() => { onCloseMenu(); router.push('/my-account'); })} variant="link" isDisabled={Boolean(!user)}>My Account</Button>
                        <Button onClick={(() => { onCloseMenu(); router.push('/my-account/orders'); })} variant="link" isDisabled={Boolean(!user)}>My Orders</Button>
                        <Button onClick={(() => { onCloseMenu(); router.push('/my-account/courses'); })} variant="link" isDisabled={Boolean(!user)}>My Courses</Button>
                        {user ? (
                          <Button variant="link" onClick={() => logout()}>Log Out</Button>
                        ) : (
                          <Button variant="link" onClick={() => openLogin()} fontSize="xl">Log In</Button>
                        )}
                        <hr />
                        <Text fontSize="xl">Site Links</Text>
                        <Button onClick={(() => { onCloseMenu(); router.push('https://ukchinaperformingarts.com/'); })} variant="link">Home</Button>
                        <Button onClick={(() => { onCloseMenu(); router.push('/class-booking'); })} variant="link">Class Booking</Button>
                        <Button onClick={(() => { onCloseMenu(); router.push('https://ukchinaperformingarts.com/about-ukcpa'); })} variant="link">About Us</Button>
                        <Button onClick={(() => { onCloseMenu(); router.push('https://ukchinaperformingarts.com/contact-us'); })} variant="link">Contact Us</Button>
                      </Stack>
                    </DrawerBody>
                  </DrawerContent>
                </DrawerOverlay>
              </Drawer>
              <Modal id="loginModal" onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Login</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <AuthForm onSuccess={onClose} />
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </HStack>
          </Box>
        </GridItem>
      </Grid>
    </Flex>
  );
};

export default Header;
