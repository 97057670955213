import React, { FunctionComponent } from 'react';
import {
  Box, Flex,
} from '@chakra-ui/react';

const Footer: FunctionComponent = (props) => (
  <Flex
    as="footer"
    align="center"
    justify="space-between"
    wrap="wrap"
    flexGrow={1}
    padding="1.5rem"
    flexDirection="column"
    {...props}
  >
    <Box>
      UK China Performing Arts Ltd ©
      {new Date().getFullYear()}
    </Box>
    <Box>VAT: 393 3761 68</Box>
  </Flex>
);

export default Footer;
