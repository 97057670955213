import { useState, useEffect, ReactElement } from 'react';
import { FiShoppingCart } from 'react-icons/fi';
import {
  Box, Button, Icon, Popover, PopoverCloseButton, PopoverContent, PopoverTrigger, Text, useDisclosure, useToast,
} from '@chakra-ui/react';
import { useGetBasketQuery } from '../../lib/graphql';

import styles from './basketIcon.module.css';
import Basket from './basketDetail';

const BasketIcon = (): ReactElement => {
  const { data: basketData } = useGetBasketQuery();
  const [itemCount, setItemCount] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    if (basketData?.getBasket?.basket?.items) {
      const itemsInBasket = basketData.getBasket.basket.items.length;
      if (itemCount !== itemsInBasket && !firstLoad) {
        if (itemCount > itemsInBasket) {
          toast({
            title: 'Item removed',
            duration: 2000,
            position: 'top-right',
            status: 'success',
          });
        } else {
          toast({
            title: 'Item added',
            duration: 2000,
            position: 'top-right',
            status: 'success',
          });
        }
      }
      setFirstLoad(false);
      setItemCount(basketData?.getBasket?.basket?.items?.length);
    } else {
      setItemCount(0);
    }
  }, [basketData]);

  return (
    <>
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom-start" closeOnBlur>
        <Box w={9} h={9} position="relative" alignItems="center" display="flex">
          <PopoverTrigger>
            <Button variant="ghost" p={2} colorScheme="blue">
              {itemCount > 0
              && (
              <Box position="absolute" className={styles.iconPulse} right={0} top={0} w={5} h={5} borderRadius="50%" bgColor="#ff0000" display="flex" alignItems="center" justifyContent="center" p={2} border="2px solid #ffffff">
                <Text fontSize={12} flex="auto" color="#ffffff" lineHeight="11px">{itemCount}</Text>
              </Box>
              )}
              <Icon as={FiShoppingCart} w="100%" h="100%" />
            </Button>
          </PopoverTrigger>
        </Box>
        <PopoverContent boxShadow="rgb(0 0 0 / 10%) 3px 3px 20px" p={3}>
          <Basket />
        </PopoverContent>
      </Popover>
    </>
  );
};

export default BasketIcon;
