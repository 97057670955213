import React, { ReactElement, ReactNode } from 'react';
import {
  Flex, Box,
} from '@chakra-ui/react';
import Sticky from 'react-stickynode';
import Header from './header';
import Footer from './footer';
import BreadcrumbNav from '../../breadcrumbNav';

type Props = {
  children: ReactNode
}

const FullWidthView = ({ children }: Props): ReactElement => (
  <Flex
    bg="blue.10"
    bgImage='url("/images/Ribbon01_80opac.png")'
    bgPosition="50%"
    bgSize="contain"
    bgAttachment="fixed"
    bgRepeat="no-repeat"
    align="flex-start"
    justify="center"
    flexGrow={1}
    flexDirection="column"
  >
    <Flex width="100%" flex="0 0 0" flexGrow={0} boxShadow="0px 0px 100px #f5f5f5">
      <Sticky enabled top={0} width="100%">
        <Header />
      </Sticky>
    </Flex>
    <Flex flex="1 1 auto" width="100%" padding={[4, 12]} flexDirection="column">
      <Box mb={4}><BreadcrumbNav /></Box>
      <Flex flex="1 0 0" justifyContent="center">
        {children}
      </Flex>
    </Flex>
    <Flex width="100%" flex="0 0 0" flexGrow={0}>
      <Footer />
    </Flex>
  </Flex>
);

export default FullWidthView;
