import { Button, useColorModeValue } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props {
    label: string
    action: () => Promise<boolean>
}

const NavLink = ({ label, action }: Props) => (
  <Button
    px={2}
    py={1}
    rounded="md"
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.300', 'gray.700'),
    }}
    onClick={action}
    size="sm"
    variant="ghost"
  >
    {label}
  </Button>
);

export default NavLink;
