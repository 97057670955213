import React, { ReactElement, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import {
  Breadcrumb, BreadcrumbItem, BreadcrumbLink,
} from '@chakra-ui/react';

function BreadcrumbNav(): ReactElement {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const router = useRouter();

  useEffect(() => {
    if (router) {
      const linkPath = router.asPath.split('?')[0].split('/');
      const homeBreadcrumb = {
        description: 'home',
        href: '/',
      };
      linkPath.shift();

      const pathArray = [homeBreadcrumb].concat(linkPath.map((path, i) => ({ description: path.replace('-', ' '), href: `/${linkPath.slice(0, i + 1).join('/')}` })));

      setBreadcrumbs(pathArray);
    }
  }, [router]);

  if (!breadcrumbs) {
    return null;
  }

  return (
    <Breadcrumb>
      {breadcrumbs.map((breadcrumb, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <BreadcrumbItem key={`breadcrumb-${index}`} isCurrentPage={index === breadcrumb.length - 1}>
          <BreadcrumbLink as={Link} href={breadcrumb.href}>
            {breadcrumb.description}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
}

export default BreadcrumbNav;
